import { useCallback, useState } from "react";
import {
	Button,
	Col,
	Form,
	Row,
	Popconfirm,
	Checkbox,
	notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { debounce } from "lodash";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";
import FloatTextArea from "../../../../providers/FloatTextArea";
import FloatSelect from "../../../../providers/FloatSelect";

export default function EmployeeFormAddressInfo(props) {
	const {
		formDisabled,
		dataRegions,
		dataProvinces,
		dataMunicipalities,
		profile_id,
	} = props;

	const [form] = Form.useForm();

	GET(
		`api/profile_address?profile_id=${profile_id}`,
		"profile_address_info",
		(res) => {
			if (res.data) {
				let data = res.data;
				console.log("profile_address data", data);

				// if (data.length > 0) {
				// 	let department_ids = data.map((item) => ({
				// 		department_id: item.department_id,
				// 		status: item.status === 1 ? true : false,
				// 	}));
				// 	form.setFieldValue("department_ids", department_ids);
				// }
			}
		}
	);

	const { mutate: mutateProfileDepartment } = POST(
		`api/profile_department`,
		"profile_department_info"
	);

	const onFinish = (values) => {
		console.log("values", values);
		// let department_ids = values.department_ids.map((item) => ({
		// 	department_id: item.department_id,
		// 	status: item.status ? 1 : 0,
		// }));

		// let data = {
		// 	profile_id: profile_id,
		// 	department_ids: department_ids,
		// };

		// mutateProfileDepartment(data, {
		// 	onSuccess: (res) => {
		// 		if (res.success) {
		// 			notification.success({
		// 				message: "Profile Department",
		// 				description: res.message,
		// 			});
		// 		} else {
		// 			notification.error({
		// 				message: "Profile Department",
		// 				description: res.message,
		// 			});
		// 		}
		// 	},
		// 	onError: (err) => {
		// 		notificationErrors(err);
		// 	},
		// });
	};

	const RenderInput = (props) => {
		const {
			formDisabled,
			form,
			name,
			restField,
			fields,
			remove,
			dataRegions,
			dataProvinces,
			dataMunicipalities,
		} = props;

		const [provinceList, setProvinceList] = useState([]);
		const [municipalityList, setMunicipalityList] = useState([]);

		const handleTriggerDebounce = debounce(() => {
			form.submit();
		}, 1000);

		const handleDebounce = useCallback(() => {
			handleTriggerDebounce();
		}, [handleTriggerDebounce]);

		const handleChangeHomeAddress = (e) => {
			let address_list = form.getFieldValue("address_list");
			address_list = address_list.map((item, index) => ({
				...item,
				is_home_address:
					index === name && e.target.checked ? e.target.value : 0,
			}));
			form.setFieldValue("address_list", address_list);

			handleDebounce(e);
		};
		const handleChangeCurrentAddress = (e) => {
			let address_list = form.getFieldValue("address_list");
			address_list = address_list.map((item, index) => ({
				...item,
				is_current_address:
					index === name && e.target.checked ? e.target.value : 0,
			}));
			form.setFieldValue("address_list", address_list);

			handleDebounce(e);
		};

		return (
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "region_id"]}
						rules={[validateRules.required()]}
					>
						<FloatSelect
							label="Region"
							placeholder="Region"
							allowClear
							required={true}
							options={dataRegions.map((item) => ({
								value: item.id,
								label: item.region,
							}))}
							onChange={(e) => {
								if (e) {
									let provincesTemp = dataProvinces.filter(
										(f) => f.region_id === e
									);
									setProvinceList(provincesTemp);
									setMunicipalityList([]);
								} else {
									setProvinceList([]);
									setMunicipalityList([]);
								}

								let address_list = form.getFieldValue("address_list");

								address_list[name] = {
									...address_list[name],
									province_id: null,
									municipality_id: null,
								};
								form.setFieldValue("address_list", address_list);

								handleDebounce(e);
							}}
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "province_id"]}
						rules={[validateRules.required()]}
					>
						<FloatSelect
							label="Province"
							placeholder="Province"
							allowClear
							required={true}
							options={provinceList.map((item) => ({
								value: item.id,
								label: item.province,
							}))}
							onChange={(e) => {
								if (e) {
									let municipalityTemp = dataMunicipalities.filter(
										(f) => f.province_id === e
									);
									setMunicipalityList(municipalityTemp);
								} else {
									setMunicipalityList([]);
								}
								let address_list = form.getFieldValue("address_list");

								address_list[name] = {
									...address_list[name],
									municipality_id: null,
								};
								form.setFieldValue("address_list", address_list);

								handleDebounce(e);
							}}
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						{...restField}
						name={[name, "municipality_id"]}
						rules={[validateRules.required()]}
					>
						<FloatSelect
							label="Municipality"
							placeholder="Municipality"
							allowClear
							required={true}
							options={municipalityList.map((item) => ({
								value: item.id,
								label: item.municipality,
							}))}
							disabled={formDisabled}
							onChange={(e) => handleDebounce(e)}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<div className="action">
						<div className="checkbox-group">
							<Form.Item
								{...restField}
								name={[name, "is_home_address"]}
								noStyle
								valuePropName="checked"
							>
								<Checkbox value={1} onChange={handleChangeHomeAddress}>
									Home Address
								</Checkbox>
							</Form.Item>

							<Form.Item
								{...restField}
								name={[name, "is_current_address"]}
								noStyle
								valuePropName="checked"
							>
								<Checkbox value={2} onChange={handleChangeCurrentAddress}>
									Current Address
								</Checkbox>
							</Form.Item>
						</div>
						{fields.length > 1 ? (
							<Popconfirm
								title="Are you sure to delete this address?"
								onConfirm={() => {
									// handleDeleteQuestion(name);
									remove(name);
								}}
								onCancel={() => {}}
								okText="Yes"
								cancelText="No"
								placement="topRight"
								okButtonProps={{
									className: "btn-main-invert",
								}}
							>
								<Button type="link" className="form-list-remove-button p-0">
									<FontAwesomeIcon icon={faTrashAlt} className="fa-lg" />
								</Button>
							</Popconfirm>
						) : null}
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.Item
						{...restField}
						name={[name, "address"]}
						rules={[validateRules.required()]}
					>
						<FloatTextArea
							label="Address"
							placeholder="Address"
							required={true}
							disabled={formDisabled}
						/>
					</Form.Item>
				</Col>
			</Row>
		);
	};

	return (
		<Form
			form={form}
			onFinish={onFinish}
			initialValues={{ address_list: [{}] }}
		>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.List name="address_list">
						{(fields, { add, remove }) => (
							<Row gutter={[12, 0]}>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									{fields.map(({ key, name, ...restField }, index) => (
										<div key={key} className={`${index !== 0 ? "mt-25" : ""}`}>
											<RenderInput
												formDisabled={formDisabled}
												form={form}
												dataRegions={dataRegions}
												dataProvinces={dataProvinces}
												dataMunicipalities={dataMunicipalities}
												name={name}
												restField={restField}
												fields={fields}
												remove={remove}
											/>
										</div>
									))}
								</Col>

								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									<Button
										type="link"
										className="btn-main-primary p-0"
										icon={<FontAwesomeIcon icon={faPlus} />}
										onClick={() => add()}
									>
										Add Another Address
									</Button>
								</Col>
							</Row>
						)}
					</Form.List>
				</Col>
			</Row>
		</Form>
	);
}
