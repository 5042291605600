import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Col, Form, Row, Button, notification } from "antd";

import { GET, POST } from "../../../providers/useAxiosQuery";
import notificationErrors from "../../../providers/notificationErrors";
import EmployeeFormPhotoInfo from "./PageEmployeeFormComponent/EmployeeFormPhotoInfo";
import EmployeeFormAccountInfo from "./PageEmployeeFormComponent/EmployeeFormAccountInfo";
import EmployeeFormBasicInfo from "./PageEmployeeFormComponent/EmployeeFormBasicInfo";

export default function PageEmployeeFormAdd(props) {
	const { refetchPermissions } = props;

	const location = useLocation();
	const navigation = useNavigate();
	const [toggleModalUploadProfilePicture, setToggleModalUploadProfilePicture] =
		useState({
			open: false,
			file: null,
			src: null,
			is_camera: null,
			fileName: null,
		});

	useEffect(() => {
		refetchPermissions();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { data: dataUserRole } = GET(
		`api/user_role?type=Employee`,
		"user_role_select",
		(res) => {},
		false
	);
	const { data: dataReligion } = GET(
		`api/ref_religion`,
		"ref_religion",
		(res) => {},
		false
	);
	const { data: dataLanguage } = GET(
		`api/ref_language`,
		"ref_language",
		(res) => {},
		false
	);
	const { data: dataNationalities } = GET(
		`api/ref_nationality`,
		"ref_nationality",
		(res) => {},
		false
	);

	const { mutate: mutateProfile, loading: loadingProfile } = POST(
		`api/profile`,
		"create_profile"
	);

	const onFinish = (values) => {
		let pathname = location.pathname.split("/");

		let employment_type =
			pathname[2] === "full-time" ? "Full-Time" : "Part-Time";

		let data = new FormData();

		data.append("employment_type", employment_type);

		Object.entries(values).forEach(([key, value]) => {
			if (key === "language_ids") {
				data.append(key, JSON.stringify(value));
			} else {
				data.append(key, value);
			}
		});

		if (toggleModalUploadProfilePicture.file) {
			data.append(
				"profile_picture",
				toggleModalUploadProfilePicture.file,
				toggleModalUploadProfilePicture.fileName
			);
		}

		mutateProfile(data, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					notification.success({
						message: "Employee",
						description: res.message,
					});
					navigation(`/employees/${pathname[2]}/edit/${res.profile_id}`);
				} else {
					notification.error({
						message: "Employee",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Form onFinish={onFinish}>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={12} md={12} lg={12} xl={12}>
					<Card
						title="Profile Photo"
						className="card-main-primary card-profile-photo"
					>
						<EmployeeFormPhotoInfo
							action="add"
							toggleModalUploadProfilePicture={toggleModalUploadProfilePicture}
							setToggleModalUploadProfilePicture={
								setToggleModalUploadProfilePicture
							}
						/>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={12} lg={12} xl={12}>
					<Card title="Account Information" className="card-main-primary">
						<EmployeeFormAccountInfo action="add" dataUserRole={dataUserRole} />
					</Card>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Card title="Personal Information" className="card-main-primary">
						<EmployeeFormBasicInfo
							action="add"
							dataReligion={dataReligion ? dataReligion.data : []}
							dataLanguage={dataLanguage ? dataLanguage.data : []}
							dataNationalities={
								dataNationalities ? dataNationalities.data : []
							}
						/>
					</Card>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						htmlType="submit"
						className="btn-main-primary w-10 w-xs-100"
						size="large"
						loading={loadingProfile}
					>
						Submit
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
