const optionRelationship = [
    { value: "Parent", label: "Parent" },
    { value: "Sibling", label: "Sibling" },
    { value: "Children", label: "Children" },
    { value: "Uncle", label: "Uncle" },
    { value: "Aunt", label: "Aunt" },
    { value: "Grandfather", label: "Grandfather" },
    { value: "Others", label: "Others" },
];
export default optionRelationship;
