import { useCallback } from "react";
import { Col, Form, Row } from "antd";
import { debounce } from "lodash";

import validateRules from "../../../../providers/validateRules";
import optionGender from "../../../../providers/optionGender";
import optionBloodType from "../../../../providers/optionBloodType";
import optionLanguage from "../../../../providers/optionLanguage";
import FloatInput from "../../../../providers/FloatInput";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatSelect from "../../../../providers/FloatSelect";

export default function EmployeeFormBasicInfo(props) {
	const { form, action, dataReligion, dataNationalities } = props;

	const handleTriggerDebounce = debounce(() => {
		form.submit();
	}, 1000);

	const handleDebounce = useCallback(() => {
		handleTriggerDebounce();
	}, [handleTriggerDebounce]);

	return (
		<Row gutter={[12, 12]}>
			{action !== "add" ? (
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<Form.Item name="school_id">
								<FloatInput
									label="School ID"
									placeholder="School ID"
									required={true}
									disabled={true}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>
			) : null}

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="firstname" rules={[validateRules.required()]}>
					<FloatInput
						label="Given Name"
						placeholder="Given Name"
						required={true}
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="middlename">
					<FloatInput label="Middle Name" placeholder="Middle Name" />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="lastname" rules={[validateRules.required()]}>
					<FloatInput
						label="Family Name"
						placeholder="Family Name"
						required={true}
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="name_ext">
					<FloatInput label="Name Extension" placeholder="Name Extension" />
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="birthplace" rules={[validateRules.required()]}>
					<FloatInput
						label="Place of Birth"
						placeholder="Place of Birth"
						required={true}
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="birthdate" rules={[validateRules.required()]}>
					<FloatDatePicker
						label="Date Of Birth"
						placeholder="Date Of Birth"
						required={true}
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="gender">
					<FloatSelect
						label="Sex"
						placeholder="Sex"
						options={optionGender}
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="height">
					<FloatInput
						label="Height"
						placeholder="Height"
						type="number"
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="weight">
					<FloatInput
						label="Weight"
						placeholder="Weight"
						type="number"
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="blood_type">
					<FloatSelect
						label="Blood Type"
						placeholder="Blood Type"
						options={optionBloodType}
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="nationality_id" rules={[validateRules.required()]}>
					<FloatSelect
						label="Citizenship"
						placeholder="Citizenship"
						required={true}
						options={dataNationalities.map((item) => ({
							value: item.id,
							label: item.nationality,
						}))}
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item name="religion_id" rules={[validateRules.required()]}>
					<FloatSelect
						label="Religion"
						placeholder="Religion"
						required={true}
						options={dataReligion.map((item) => ({
							value: item.id,
							label: item.religion,
						}))}
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={12} md={12} lg={6} xl={6}>
				<Form.Item
					name="language_ids"
					className={action === "add" ? "mb-5" : "mb-5"}
				>
					<FloatSelect
						label="Language"
						placeholder="Language"
						multi="multiple"
						options={optionLanguage}
						onChange={(e) => {
							if (action === "edit") {
								handleDebounce(e);
							}
						}}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
}
