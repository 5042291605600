import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Button, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { GET } from "../../../providers/useAxiosQuery";
import TableStudent from "./components/TableStudent";

export default function PageStudent(props) {
	const { refetchPermissions } = props;

	const navigate = useNavigate();
	const location = useLocation();

	const [sortInfo, setSortInfo] = useState({
		order: "descend",
		columnKey: "created_at",
		status:
			location.pathname === "/students/current" ? "Active" : "Deactivated",
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at",
		sort_order: "desc",
		status: "Active",
		from: location.pathname,
	});

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			sort_field: "created_at",
			sort_order: "desc",
			status:
				location.pathname === "/students/current" ? "Active" : "Deactivated",
			from: location.pathname,
		});

		setSortInfo({
			order: "descend",
			columnKey: "created_at",
		});

		return () => {};
	}, [location]);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/profile?${new URLSearchParams(tableFilter)}`,
		"students_active_list",
		(res) => {
			refetchPermissions();
		}
	);

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	return (
		<Row gutter={[12, 12]}>
			{location.pathname === "/students/current" ? (
				<Col xs={24} sm={24} md={24}>
					<Button
						className=" btn-main-primary btn-main-invert-outline b-r-none hide"
						icon={<FontAwesomeIcon icon={faPlus} />}
						onClick={() => navigate(`/students/current/add`)}
						size="large"
						name="btn_add"
					>
						Add Student
					</Button>
				</Col>
			) : null}

			<Col xs={24} sm={24} md={24}>
				<TableStudent
					dataSource={dataSource}
					tableFilter={tableFilter}
					setTableFilter={setTableFilter}
					sortInfo={sortInfo}
					setSortInfo={setSortInfo}
					location={location}
				/>
			</Col>
		</Row>
	);
}
