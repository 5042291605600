import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Card, Col, Form, Row, Tabs, notification } from "antd";

import { GET, POST } from "../../../providers/useAxiosQuery";
import notificationErrors from "../../../providers/notificationErrors";
import EmployeeFormPhotoInfo from "./PageEmployeeFormComponent/EmployeeFormPhotoInfo";
import EmployeeFormAccountInfo from "./PageEmployeeFormComponent/EmployeeFormAccountInfo";
import EmployeeFormBasicInfo from "./PageEmployeeFormComponent/EmployeeFormBasicInfo";
import EmployeeFormDepartmentInfo from "./PageEmployeeFormComponent/EmployeeFormDepartmentInfo";
import EmployeeFormAddressInfo from "./PageEmployeeFormComponent/EmployeeFormAddressInfo";
import EmployeeFormContactInfo from "./PageEmployeeFormComponent/EmployeeFormContactInfo";
import EmployeeFormFamilyInfo from "./PageEmployeeFormComponent/EmployeeFormFamilyInfo";
import EmployeeFormSchoolAttendedInfo from "./PageEmployeeFormComponent/EmployeeFormSchoolAttendedInfo";
import EmployeeFormOtherQualificationInfo from "./PageEmployeeFormComponent/EmployeeFormOtherQualificationInfo";
import EmployeeFormExaminationInfo from "./PageEmployeeFormComponent/EmployeeFormExaminationInfo";
import EmployeeFormProjects from "./PageEmployeeFormComponent/EmployeeFormProjects";
import EmployeeFormMembershipInfo from "./PageEmployeeFormComponent/EmployeeFormMembershipInfo";
import EmployeeFormEducationTravelInfo from "./PageEmployeeFormComponent/EmployeeFormEducationTravelInfo";
import EmployeeFormReferences from "./PageEmployeeFormComponent/EmployeeFormReferences";
import EmployeeFormEmergencyContactInfo from "./PageEmployeeFormComponent/EmployeeFormEmergencyContactInfo";
import EmployeeFormBenificiaryInfo from "./PageEmployeeFormComponent/EmployeeFormBenificiaryInfo";
import EmployeeFormParentInfo from "./PageEmployeeFormComponent/EmployeeFormParentInfo";
import EmployeeFormWorkExperienceInfo from "./PageEmployeeFormComponent/EmployeeFormWorkExperienceInfo";
import EmployeeFormTrainingCertificateInfo from "./PageEmployeeFormComponent/EmployeeFormTrainingCertificateInfo";

export default function PageEmployeeFormEdit(props) {
	const { refetchPermissions } = props;

	const location = useLocation();
	const params = useParams();

	let profile_id = params.id;

	const [form] = Form.useForm();

	const [toggleModalUploadProfilePicture, setToggleModalUploadProfilePicture] =
		useState({
			open: false,
			file: null,
			src: null,
			is_camera: null,
			fileName: null,
		});

	const { data: dataUserRole } = GET(
		`api/user_role?type=Employee`,
		"user_role_select",
		(res) => {},
		false
	);
	const { data: dataReligion } = GET(
		`api/ref_religion`,
		"ref_religion",
		(res) => {},
		false
	);
	const { data: dataLanguage } = GET(
		`api/ref_language`,
		"ref_language",
		(res) => {},
		false
	);
	const { data: dataNationalities } = GET(
		`api/ref_nationality`,
		"ref_nationality",
		(res) => {},
		false
	);
	const { data: dataDepartments } = GET(
		`api/ref_department`,
		"ref_department",
		(res) => {},
		false
	);

	const { data: dataRegions } = GET(
		`api/ref_region`,
		"ref_region",
		(res) => {},
		false
	);
	const { data: dataProvinces } = GET(
		`api/ref_province`,
		"ref_province",
		(res) => {},
		false
	);
	const { data: dataMunicipalities } = GET(
		`api/ref_municipality`,
		"ref_municipality",
		(res) => {},
		false
	);
	const { data: dataCivilStatus } = GET(
		`api/ref_civilstatus`,
		"ref_civilstatus",
		(res) => {},
		false
	);
	const { data: dataSchoolLevel } = GET(
		`api/ref_school_level`,
		"ref_school_level",
		(res) => {},
		false
	);

	const { data: dataPosition } = GET(
		`api/ref_position`,
		"ref_position_select",
		(res) => {},
		false
	);

	GET(
		`api/profile/${profile_id}`,
		["profile_edit_info", "check_user_permission"],
		(res) => {
			if (res.data) {
				let data = res.data;

				console.log("profile_edit_info data", data);
				let language_ids = data.profile_languages.reduce((a, b) => {
					return [...a, b.language_id];
				}, []);

				form.setFieldsValue({
					username: data.user.username,
					email: data.user.email,
					user_role_id: data.user.user_role_id,
					school_id: data.school_id,
					firstname: data.firstname,
					middlename: data.middlename,
					lastname: data.lastname,
					name_ext: data.name_ext,
					birthplace: data.birthplace,
					birthdate: data.birthdate,
					gender: data.gender,
					height: data.height,
					weight: data.weight,
					blood_type: data.blood_type,
					nationality_id: data.nationality_id,
					religion_id: data.religion_id,
					language_ids,
				});

				let attachments = data.attachments;

				if (attachments.length > 0) {
					setToggleModalUploadProfilePicture((ps) => ({
						...ps,
						src: attachments[0].file_path,
					}));
				}
			}

			refetchPermissions();
		}
	);

	const { mutate: mutateProfile } = POST(
		`api/profile_update`,
		`profile_update_${profile_id}`
	);

	const onFinishFormBasic = (values) => {
		let pathname = location.pathname.split("/");

		let employment_type =
			pathname[2] === "full-time" ? "Full-Time" : "Part-Time";

		let data = new FormData();

		data.append("id", profile_id);
		data.append("employment_type", employment_type);

		Object.entries(values).forEach(([key, value]) => {
			if (key === "language_ids") {
				data.append(key, JSON.stringify(value));
			} else if (key === "birthdate") {
				data.append(key, value ? value.format("YYYY-MM-DD") : "");
			} else {
				data.append(key, value);
			}
		});

		if (toggleModalUploadProfilePicture.file) {
			data.append(
				"profile_picture",
				toggleModalUploadProfilePicture.file,
				toggleModalUploadProfilePicture.fileName
			);
		}

		mutateProfile(data, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					notification.success({
						message: "Employee",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Employee",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Form onFinish={onFinishFormBasic} form={form}>
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<Card
								title="Profile Photo"
								className="card-main-primary card-profile-photo"
							>
								<EmployeeFormPhotoInfo
									form={form}
									action="edit"
									toggleModalUploadProfilePicture={
										toggleModalUploadProfilePicture
									}
									setToggleModalUploadProfilePicture={
										setToggleModalUploadProfilePicture
									}
								/>
							</Card>
						</Col>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<Card
								title="Account Information"
								className="card-main-primary card-body-pb-10"
							>
								<EmployeeFormAccountInfo
									form={form}
									action="edit"
									dataUserRole={dataUserRole}
								/>
							</Card>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Card title="Personal Information" className="card-main-primary">
								<EmployeeFormBasicInfo
									form={form}
									action="edit"
									dataReligion={dataReligion ? dataReligion.data : []}
									dataLanguage={dataLanguage ? dataLanguage.data : []}
									dataNationalities={
										dataNationalities ? dataNationalities.data : []
									}
								/>
							</Card>
						</Col>
					</Row>
				</Form>
			</Col>

			{params && params.id ? (
				<Col sm={24} md={24} lg={24} xl={24} xxl={24}>
					<Tabs
						type="card"
						defaultActiveKey="2"
						items={[
							{
								key: "1",
								label: "DEPARTMENT INFORMATION",
								children: (
									<EmployeeFormDepartmentInfo
										profile_id={profile_id}
										location={location}
										dataDepartments={
											dataDepartments ? dataDepartments.data : []
										}
									/>
								),
							},
							{
								key: "2",
								label: "ADDRESS INFORMATION",
								children: (
									<EmployeeFormAddressInfo
										profile_id={profile_id}
										location={location}
										dataRegions={
											dataRegions && dataRegions.data ? dataRegions.data : []
										}
										dataProvinces={
											dataProvinces && dataProvinces.data
												? dataProvinces.data
												: []
										}
										dataMunicipalities={
											dataMunicipalities && dataMunicipalities.data
												? dataMunicipalities.data
												: []
										}
									/>
								),
							},
							{
								key: "3",
								label: "CONTACT INFORMATION",
								children: (
									<EmployeeFormContactInfo
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "4",
								label: "FAMILY INFORMATION",
								children: (
									<EmployeeFormFamilyInfo
										profile_id={profile_id}
										location={location}
										dataCivilStatus={
											dataCivilStatus && dataCivilStatus.data
												? dataCivilStatus.data
												: []
										}
									/>
								),
							},
							{
								key: "5",
								label: "SCHOOL ATTENDED INFORMATION",
								children: (
									<EmployeeFormSchoolAttendedInfo
										profile_id={profile_id}
										location={location}
										dataSchoolLevel={
											dataSchoolLevel ? dataSchoolLevel.data : []
										}
									/>
								),
							},
							{
								key: "6",
								label:
									"OTHER QUALIFICATION ( PROFICIENCY, VOCATIONAL, TECHNICAL, ETC.) INFORMATION",
								children: (
									<EmployeeFormOtherQualificationInfo
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "7",
								label: "EXAMINATIONS TAKEN INFORMATION",
								className: "collapse-item-examination-info",
								children: (
									<EmployeeFormExaminationInfo
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "8",
								label: "ARTICLES, RESEARCHES, BOOKS, ETC. WRITTEN INFORMATION",
								children: (
									<EmployeeFormProjects
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "9",
								label:
									"MEMBERSHIP IN PROFESSIONAL, CULTURAL AND OTHER ORGANIZATION INFORMATION",
								children: (
									<EmployeeFormMembershipInfo
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "10",
								label: "EDUCATIONAL TRAVEL INFORMATION",
								children: (
									<EmployeeFormEducationTravelInfo
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "11",
								label: "REFERENCES AND THEIR ADDRESSES (At least three)",
								children: (
									<EmployeeFormReferences
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "12",
								label: "WHOM TO INFORM IN CASE OF EMERGENCY",
								children: (
									<EmployeeFormEmergencyContactInfo
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "13",
								label: "BENIFICIARY INFORMATION",
								children: (
									<EmployeeFormBenificiaryInfo
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "14",
								label: "PARENT INFORMATION",
								children: (
									<EmployeeFormParentInfo
										profile_id={profile_id}
										location={location}
									/>
								),
							},
							{
								key: "15",
								label: "WORK EXPERIENCE INFORMATION",
								children: (
									<EmployeeFormWorkExperienceInfo
										profile_id={profile_id}
										location={location}
										dataPosition={dataPosition ? dataPosition.data : []}
									/>
								),
							},
							{
								key: "16",
								label: "TRAINING CERTIFICATE INFORMATION",
								children: (
									<EmployeeFormTrainingCertificateInfo
										profile_id={profile_id}
										location={location}
									/>
								),
							},
						]}
					/>
				</Col>
			) : null}
		</Row>
	);
}
