import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-regular-svg-icons";

import { defaultProfile } from "../../../../providers/companyInfo";
import ModalUploadProfilePicture from "./ModalUploadProfilePicture";

export default function EmployeeFormPhotoInfo(props) {
	const {
		form,
		action,
		toggleModalUploadProfilePicture,
		setToggleModalUploadProfilePicture,
	} = props;

	return (
		<>
			<div className="profile-picture-wrapper">
				<img
					alt=""
					src={
						toggleModalUploadProfilePicture.src
							? toggleModalUploadProfilePicture.src
							: defaultProfile
					}
				/>

				<Button
					type="link"
					icon={<FontAwesomeIcon icon={faCamera} />}
					className="btn-upload"
					onClick={() =>
						setToggleModalUploadProfilePicture((ps) => ({ ...ps, open: true }))
					}
				/>
			</div>

			<ModalUploadProfilePicture
				toggleModalUploadProfilePicture={toggleModalUploadProfilePicture}
				setToggleModalUploadProfilePicture={setToggleModalUploadProfilePicture}
				form={form}
				action={action}
			/>
		</>
	);
}
