import { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "antd";

import FloatInput from "../../../../providers/FloatInput";
import FloatInputPassword from "../../../../providers/FloatInputPassword";
import validateRules from "../../../../providers/validateRules";
import ModalFormPassword from "../components/ModalFormPassword";
import { POST } from "../../../../providers/useAxiosQuery";
import { userData } from "../../../../providers/companyInfo";

export default function ProfileFormAccountInfo(props) {
	const { formDisabled, location } = props;
	const [toggleModalFormChangePassword, setToggleModalFormChangePassword] =
		useState({
			open: false,
			data: null,
		});

	const { mutate: mutateUpdateAccount, loading: loadingUpdateAccount } = POST(
		`api/update_profile`,
		"profiles"
	);

	const onFinish = (values) => {
		console.log("onFinish", values);

		let data = {
			...values,
			id: userData.data && userData.data.id ? userData.data.id : "",
		};
	};

	useEffect(() => {});

	return (
		<Row gutter={[18, 0]}>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<Form.Item name="username" rules={[validateRules.required()]}>
					<FloatInput
						label="Username"
						placeholder="Username"
						required
						disabled
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<Form.Item
					name="email"
					rules={[validateRules.email, validateRules.required()]}
				>
					<FloatInput
						label="Email"
						placeholder="Email"
						required
						disabled
						// disabled={formDisabled}
					/>
				</Form.Item>
			</Col>

			<>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Form.Item
						name="password"
						rules={[validateRules.required(), validateRules.password]}
					>
						<FloatInputPassword
							label="Password"
							placeholder="Password"
							disabled={formDisabled}
							autoComplete="new-password"
							required
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Form.Item
						name="confirm-password"
						rules={[validateRules.required(), validateRules.password_validate]}
					>
						<FloatInputPassword
							label="Confirm Password"
							placeholder="Confirm Password"
							disabled={formDisabled}
							required
						/>
					</Form.Item>
				</Col>
			</>
			<>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						type="link"
						className="btn-main-primary p-0"
						onClick={() =>
							setToggleModalFormChangePassword({
								open: true,
								// data: {
								// 	id: userData().id,
								// },
							})
						}
					>
						Change Password
					</Button>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button type="link" className="btn-main-primary p-0">
						CLICK HERE
					</Button>
					<span>to enabled 2-Factor Authentication (2FA)</span>
				</Col>
			</>

			<ModalFormPassword
				toggleModalFormChangePassword={toggleModalFormChangePassword}
				setToggleModalFormChangePassword={setToggleModalFormChangePassword}
			/>
		</Row>
	);
}
