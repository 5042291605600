import { Col, Form, Row, Button } from "antd";

import validateRules from "../../../../providers/validateRules";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";

export default function EmployeeFormAccountInfo(props) {
	const { action, dataUserRole } = props;

	return (
		<Row gutter={[18, 0]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Form.Item name="username" rules={[validateRules.required()]}>
					<FloatInput
						label="Username"
						placeholder="Username"
						required
						disabled={action === "edit" ?? false}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Form.Item
					name="email"
					rules={[validateRules.email, validateRules.required()]}
				>
					<FloatInput
						label="Email"
						placeholder="Email"
						required
						disabled={action === "edit" ?? false}
					/>
				</Form.Item>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Form.Item
					name="user_role_id"
					className={action === "add" ? "mb-5" : "mb-10"}
				>
					<FloatSelect
						label="Role"
						placeholder="Role"
						disabled={action === "edit" ?? false}
						options={
							dataUserRole && dataUserRole.data.length > 0
								? dataUserRole.data.map((item) => ({
										value: item.id,
										label: item.role,
								  }))
								: []
						}
					/>
				</Form.Item>
			</Col>

			{action === "edit" ? (
				<>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button type="link" className="btn-main-primary p-0">
							Update Role
						</Button>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button type="link" className="btn-main-primary p-0">
							Change Email
						</Button>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button type="link" className="btn-main-primary p-0">
							Change Password
						</Button>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button type="link" className="btn-main-primary p-0">
							CLICK HERE
						</Button>{" "}
						<span>to enabled 2-Factor Authentication (2FA)</span>
					</Col>
				</>
			) : null}
		</Row>
	);
}
