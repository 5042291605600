import {
	faHome,
	faUsers,
	faCogs,
	faUserGear,
	faUserTie,
	faGraduationCap,
	faCalendarDay,
} from "@fortawesome/pro-light-svg-icons";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "antd";

export const adminHeaderMenuLeft = (
	<>
		{/* <div className="ant-menu-left-icon">
            <Link to="/subscribers/current">
                <span className="anticon">
                    <FontAwesomeIcon icon={faUsers} />
                </span>
                <Typography.Text>Subscribers</Typography.Text>
            </Link>
        </div> */}
	</>
);

export const adminHeaderDropDownMenuLeft = () => {
	const items = [
		// {
		//     key: "/subscribers/current",
		//     icon: <FontAwesomeIcon icon={faUsers} />,
		//     label: <Link to="/subscribers/current">Subscribers</Link>,
		// },
	];

	return <Menu items={items} />;
};

export const adminSideMenu = [
	{
		title: "Dashboard",
		path: "/dashboard",
		icon: <FontAwesomeIcon icon={faHome} />,
		moduleCode: "M-01",
	},
	{
		title: "Profile",
		path: "/profile",
		icon: <FontAwesomeIcon icon={faUser} />,
		moduleCode: "M-13",
	},
	{
		title: "Schedules",
		path: "/schedules",
		icon: <FontAwesomeIcon icon={faCalendarDay} />,
		children: [
			{
				title: "Scheduling",
				path: "/schedules/scheduling",
				moduleCode: "M-02",
			},
			{
				title: "Schedule Day Time",
				path: "/schedules/schedule-day-time",
				moduleCode: "M-03",
			},
			{
				title: "Faculty Schedule",
				path: "/schedules/faculty-schedule",
				moduleCode: "M-04",
			},
			{
				title: "Student Schedule",
				path: "/schedules/student-schedule",
				moduleCode: "M-05",
			},
		],
	},
	{
		title: "Employees",
		path: "/employees",
		icon: <FontAwesomeIcon icon={faUserTie} />,
		children: [
			// {
			//     title: "All",
			//     path: "/employees/all",
			//     moduleCode: "M-02",
			// },
			{
				title: "Full-time",
				path: "/employees/full-time",
				moduleCode: "M-06",
			},
			{
				title: "Part-time",
				path: "/employees/part-time",
				moduleCode: "M-07",
			},
			{
				title: "Archived",
				path: "/employees/archived",
				moduleCode: "M-08",
			},
		],
	},
	{
		title: "Students",
		path: "/students",
		icon: <FontAwesomeIcon icon={faGraduationCap} />,
		children: [
			{
				title: "Current",
				path: "/students/current",
				moduleCode: "M-09",
			},
			{
				title: "Archived",
				path: "/students/archived",
				moduleCode: "M-10",
			},
		],
	},
	{
		title: "Users",
		path: "/users",
		icon: <FontAwesomeIcon icon={faUsers} />,
		children: [
			{
				title: "Current",
				path: "/users/current",
				moduleCode: "M-11",
			},
			{
				title: "Archived",
				path: "/users/archived",
				moduleCode: "M-12",
			},
		],
	},
	{
		title: "Admin Settings",
		path: "/admin-settings",
		icon: <FontAwesomeIcon icon={faCogs} />,
		moduleCode: "M-13",
	},
	{
		title: "Permission",
		path: "/permission",
		icon: <FontAwesomeIcon icon={faUserGear} />,
		children: [
			{
				title: "OPIS",
				path: "/permission/opis",
				moduleCode: "M-14",
			},
			{
				title: "Faculty Monitoring",
				path: "/permission/faculty-monitoring",
				moduleCode: "M-15",
			},
		],
	},
];
