import {
	Button,
	Checkbox,
	Col,
	Form,
	Popconfirm,
	Row,
	notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";
import validateRules from "../../../../providers/validateRules";
import FloatSelect from "../../../../providers/FloatSelect";
import { debounce } from "lodash";
import { useCallback } from "react";

export default function EmployeeFormDepartmentInfo(props) {
	const { formDisabled, dataDepartments, profile_id } = props;

	const [form] = Form.useForm();

	GET(
		`api/profile_department?profile_id=${profile_id}`,
		"profile_department_info",
		(res) => {
			if (res.data) {
				let data = res.data;

				if (data.length > 0) {
					let department_ids = data.map((item) => ({
						department_id: item.department_id,
						status: item.status === 1 ? true : false,
					}));
					form.setFieldValue("department_ids", department_ids);
				}
			}
		}
	);

	const { mutate: mutateProfileDepartment } = POST(
		`api/profile_department`,
		"profile_department_info"
	);

	const onFinish = (values) => {
		let department_ids = values.department_ids.map((item) => ({
			department_id: item.department_id,
			status: item.status ? 1 : 0,
		}));

		let data = {
			profile_id: profile_id,
			department_ids: department_ids,
		};

		mutateProfileDepartment(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Profile Department",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Profile Department",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const RenderInput = (props) => {
		const { formDisabled, name, restField, fields, remove } = props;

		const handleTriggerDebounce = debounce(() => {
			form.submit();
		}, 1000);

		const handleDebounce = useCallback(() => {
			handleTriggerDebounce();
		}, [handleTriggerDebounce]);

		return (
			<Row gutter={[12, 0]}>
				<Col xs={2} sm={2} md={2} lg={2} xl={2}>
					<Form.Item
						{...restField}
						name={[name, "status"]}
						valuePropName="checked"
					>
						<Checkbox
							onChange={(e) => {
								let department_ids = form.getFieldValue("department_ids");
								department_ids = department_ids.map((item, index) => ({
									...item,
									status: index === name ? e.target.checked : false,
								}));
								form.setFieldValue("department_ids", department_ids);

								handleDebounce(e);
							}}
						>
							Primary
						</Checkbox>
					</Form.Item>
				</Col>

				<Col xs={21} sm={21} md={21} lg={21} xl={21}>
					<Form.Item
						{...restField}
						name={[name, "department_id"]}
						rules={[validateRules.required()]}
					>
						<FloatSelect
							label="Department"
							placeholder="Department"
							allowClear
							options={dataDepartments.map((item) => ({
								value: item.id,
								label: item.department_name,
							}))}
							disabled={formDisabled}
							onChange={(e) => {
								handleDebounce(e);
							}}
						/>
					</Form.Item>
				</Col>

				<Col xs={1} sm={1} md={1} lg={1} xl={1}>
					<div className="action">
						<div />
						{fields.length > 1 ? (
							<Popconfirm
								title="Are you sure to delete this address?"
								onConfirm={() => {
									// handleDeleteQuestion(name);
									remove(name);
								}}
								onCancel={() => {}}
								okText="Yes"
								cancelText="No"
								placement="topRight"
								okButtonProps={{
									className: "btn-main-invert",
								}}
							>
								<Button type="link" className="form-list-remove-button p-0">
									<FontAwesomeIcon icon={faTrashAlt} className="fa-lg" />
								</Button>
							</Popconfirm>
						) : null}
					</div>
				</Col>
			</Row>
		);
	};

	return (
		<Form
			form={form}
			onFinish={onFinish}
			initialValues={{
				department_ids: [{}],
			}}
		>
			<Form.List name="department_ids">
				{(fields, { add, remove }) => (
					<Row gutter={[12, 0]}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							{fields.map(({ key, name, ...restField }, index) => (
								<div key={key}>
									<RenderInput
										formDisabled={formDisabled}
										name={name}
										restField={restField}
										fields={fields}
										remove={remove}
									/>
								</div>
							))}
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Button
								type="link"
								className="btn-main-primary p-0"
								icon={<FontAwesomeIcon icon={faPlus} />}
								onClick={() => add()}
							>
								Add Another Department
							</Button>
						</Col>
					</Row>
				)}
			</Form.List>
		</Form>
	);
}
