const optionBloodType = [
    { value: "AB", label: "AB" },
    { value: "AB +", label: "AB +" },
    { value: "AB -", label: "AB -" },
    { value: "B", label: "B" },
    { value: "B -", label: "B -" },
    { value: "B +", label: "B +" },
    { value: "O", label: "O" },
    { value: "O -", label: "O -" },
    { value: "O +", label: "O +" },
];
export default optionBloodType;
