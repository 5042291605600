import { Routes, Route } from "react-router-dom";
import {
	faBooks,
	faHome,
	faUsers,
	faUserPlus,
	faUserTie,
	faGraduationCap,
	faCalendarDay,
	faUser,
} from "@fortawesome/pro-regular-svg-icons";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import Page404 from "../views/errors/Page404";
import PageRequestPermission from "../views/errors/PageRequestPermission";

import PageLogin from "../views/public/PageLogin/PageLogin";
import PageRegister from "../views/public/PageRegister/PageRegister";

import PageEditProfile from "../views/private/PageEditProfile/PageEditProfile";
import PageProfileForm from "../views/private/PageProfile/PageProfileForm";

import PageDashboard from "../views/private/PageDashboard/PageDashboard";
import PageUser from "../views/private/PageUser/PageUser";
import PageUserForm from "../views/private/PageUser/PageUserForm";
import PageUserPermission from "../views/private/PageUser/PageUserPermission";

import PageEmployee from "../views/private/PageEmployee/PageEmployee";
import PageEmployeeFormAdd from "../views/private/PageEmployee/PageEmployeeFormAdd";
import PageEmployeeFormEdit from "../views/private/PageEmployee/PageEmployeeFormEdit";

import PageStudent from "../views/private/PageStudent/PageStudent";
import PageStudentForm from "../views/private/PageStudent/PageStudentForm";

import PageScheduleFaculty from "../views/private/PageSchedule/PageScheduleFaculty";
import PageScheduleStudent from "../views/private/PageSchedule/PageScheduleStudent";

import PageSettings from "../views/private/PageReferences/PageSettings/PageSettings";

import PageScheduleScheduling from "../views/private/PageSchedule/PageScheduleScheduling";
import PageScheduleDayTime from "../views/private/PageSchedule/PageScheduleDayTime";

export default function RouteList() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>

			<Route
				path="/registration"
				element={
					<PublicRoute
						title="REGISTRATION"
						pageId="PageRegistration"
						component={PageRegister}
					/>
				}
			/>

			<Route
				path="/edit-profile"
				element={
					<PrivateRoute
						moduleName="Edit Profile"
						title="User"
						subtitle="VIEW / EDIT"
						pageId="PageUserProfile"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Edit Profile",
							},
						]}
						component={PageEditProfile}
					/>
				}
			/>

			<Route
				path="/dashboard"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Dashboard"
						title="Dashboard"
						subtitle="ADMIN"
						pageId="PageDashboard"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Dashboard",
							},
						]}
						component={PageDashboard}
					/>
				}
			/>

			<Route
				path="/profile"
				element={
					<PrivateRoute
						moduleCode="M-13"
						moduleName="Profile"
						title="Profile"
						subtitle="VIEW / EDIT"
						pageId="PageProfile"
						pageHeaderIcon={faUser}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Edit Profile",
							},
						]}
						component={PageProfileForm}
					/>
				}
			/>

			{/* SCHEDULES */}
			<Route
				path="/schedules/scheduling"
				element={
					<PrivateRoute
						moduleCode="M-02"
						moduleName="Schedules Scheduling"
						title="Schedule"
						subtitle="SCHEDULING"
						pageId="PageScheduleScheduling"
						pageHeaderIcon={faCalendarDay}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Schedule",
							},
							{
								name: "Scheduling",
							},
						]}
						component={PageScheduleScheduling}
					/>
				}
			/>

			<Route
				path="/schedules/schedule-day-time"
				element={
					<PrivateRoute
						moduleCode="M-03"
						moduleName="Schedule Day Time"
						title="Schedule"
						subtitle="DAY TIME"
						pageId="PageScheduleDayTime"
						pageHeaderIcon={faCalendarDay}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Schedule",
							},
							{
								name: "Day Time",
							},
						]}
						component={PageScheduleDayTime}
					/>
				}
			/>

			<Route
				path="/schedules/faculty-schedule"
				element={
					<PrivateRoute
						moduleCode="M-04"
						moduleName="Schedule Faculty"
						title="Schedule"
						subtitle="FACULTY"
						pageId="PageScheduleFaculty"
						pageHeaderIcon={faCalendarDay}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Schedule",
							},
							{
								name: "Faculty",
							},
						]}
						component={PageScheduleFaculty}
					/>
				}
			/>

			<Route
				path="/schedules/student-schedule"
				element={
					<PrivateRoute
						moduleCode="M-05"
						moduleName="Schedule Student"
						title="Schedule"
						subtitle="STUDENT"
						pageId="PageScheduleStudent"
						pageHeaderIcon={faCalendarDay}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Schedule",
							},
							{
								name: "Student",
							},
						]}
						component={PageScheduleStudent}
					/>
				}
			/>

			{/* EMPLOYEES */}
			<Route
				path="/employees/full-time"
				element={
					<PrivateRoute
						moduleCode="M-06"
						moduleName="Employee Full-time"
						title="Employee"
						subtitle="FULL-TIME"
						pageId="PageEmployees"
						pageHeaderIcon={faUserTie}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Employee",
							},
							{
								name: "Full-time",
							},
						]}
						component={PageEmployee}
					/>
				}
			/>

			<Route
				path="/employees/full-time/add"
				element={
					<PrivateRoute
						moduleCode="M-06"
						moduleName="Employee Full-time Add"
						title="Employee"
						subtitle="FULL-TIME ADD"
						pageId="PageEmployeeForm"
						pageHeaderIcon={faUserPlus}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Employee",
								link: "/employees/full-time",
							},
							{
								name: "Full-time",
								link: "/employees/full-time",
							},
							{
								name: "Add",
							},
						]}
						component={PageEmployeeFormAdd}
					/>
				}
			/>

			<Route
				path="/employees/full-time/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-06"
						moduleName="Employee Full-time Edit"
						title="Employee"
						subtitle="FULL-TIME EDIT"
						pageId="PageEmployeeForm"
						pageHeaderIcon={faUserPlus}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Employee",
								link: "/employees/full-time",
							},
							{
								name: "Full-time",
								link: "/employees/full-time",
							},
							{
								name: "Edit",
							},
						]}
						component={PageEmployeeFormEdit}
					/>
				}
			/>

			<Route
				path="/employees/part-time"
				element={
					<PrivateRoute
						moduleCode="M-07"
						moduleName="Employee Part-time"
						title="Employee"
						subtitle="PART-TIME"
						pageId="PageEmployees"
						pageHeaderIcon={faUserTie}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Employee",
							},
							{
								name: "Part-time",
							},
						]}
						component={PageEmployee}
					/>
				}
			/>

			<Route
				path="/employees/part-time/add"
				element={
					<PrivateRoute
						moduleCode="M-07"
						moduleName="Employee Part-time Add"
						title="Employee"
						subtitle="FULL-TIME ADD"
						pageId="PageEmployeeForm"
						pageHeaderIcon={faUserPlus}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Employee",
								link: "/employees/part-time",
							},
							{
								name: "Part-time",
								link: "/employees/part-time",
							},
							{
								name: "Add",
							},
						]}
						component={PageEmployeeFormAdd}
					/>
				}
			/>

			<Route
				path="/employees/part-time/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-07"
						moduleName="Employee Part-time Edit"
						title="Employee"
						subtitle="PART-TIME EDIT"
						pageId="PageEmployeeForm"
						pageHeaderIcon={faUserPlus}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Employee",
								link: "/employees/part-time",
							},
							{
								name: "Part-time",
								link: "/employees/part-time",
							},
							{
								name: "Edit",
							},
						]}
						component={PageEmployeeFormEdit}
					/>
				}
			/>

			<Route
				path="/employees/archived"
				element={
					<PrivateRoute
						moduleCode="M-08"
						moduleName="Employee Current Archived"
						title="Employee"
						subtitle="ARCHIVED"
						pageId="PageEmployeeForm"
						pageHeaderIcon={faUserTie}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Employee",
							},
							{
								name: "Archived",
							},
						]}
						component={PageEmployee}
					/>
				}
			/>

			<Route
				path="/employees/archived/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-08"
						moduleName="Employee Archived Edit"
						title="Employee"
						subtitle="EDIT"
						pageId="PageEmployeeForm"
						pageHeaderIcon={faUserPlus}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Employee",
								link: "/employees/archived",
							},
							{
								name: "Archived",
								link: "/employees/archived",
							},
							{
								name: "Edit",
							},
						]}
						component={PageEmployeeFormEdit}
					/>
				}
			/>

			{/* STUDENT */}
			<Route
				path="/students/current"
				element={
					<PrivateRoute
						moduleCode="M-09"
						moduleName="Student Current"
						title="Student"
						subtitle="CURRENT"
						pageId="PageStudent"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Student",
							},
							{
								name: "Current",
							},
						]}
						component={PageStudent}
					/>
				}
			/>

			<Route
				path="/students/current/add"
				element={
					<PrivateRoute
						moduleCode="M-09"
						moduleName="Student Current Add"
						title="Student"
						subtitle="ADD"
						pageId="PageStudentForm"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Student",
								link: "/students/current",
							},
							{
								name: "Current",
								link: "/students/current",
							},
							{
								name: "Add",
							},
						]}
						component={PageStudentForm}
					/>
				}
			/>

			<Route
				path="/students/current/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-09"
						moduleName="Student Current Edit"
						title="Student"
						subtitle="EDIT"
						pageId="PageStudentForm"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Student",
								link: "/students/current",
							},
							{
								name: "Current",
								link: "/students/current",
							},
							{
								name: "Edit",
							},
						]}
						component={PageStudentForm}
					/>
				}
			/>

			<Route
				path="/students/archived"
				element={
					<PrivateRoute
						moduleCode="M-10"
						moduleName="Student Archived"
						title="Student"
						subtitle="ARCHIVED"
						pageId="PageStudentForm"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Student",
							},
							{
								name: "Archived",
							},
						]}
						component={PageStudent}
					/>
				}
			/>

			<Route
				path="/students/archived/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-10"
						moduleName="Student Archived Edit"
						title="Student"
						subtitle="EDIT"
						pageId="PageStudentForm"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Student",
								link: "/students/archived",
							},
							{
								name: "Archived",
								link: "/students/archived",
							},
							{
								name: "Edit",
							},
						]}
						component={PageStudentForm}
					/>
				}
			/>

			{/* USERS */}
			<Route
				path="/users/current"
				element={
					<PrivateRoute
						moduleCode="M-11"
						moduleName="User Current"
						title="Users"
						subtitle="VIEW / EDIT"
						pageId="PageUserCurrent"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Users",
							},
							{
								name: "Current",
							},
						]}
						component={PageUser}
					/>
				}
			/>

			<Route
				path="/users/current/add"
				element={
					<PrivateRoute
						moduleCode="M-11"
						moduleName="User Current Add"
						title="Users"
						subtitle="ADD"
						pageId="PageUserAdd"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Users",
								link: "/users/current",
							},
							{
								name: "Current",
								link: "/users/current",
							},
							{
								name: "Add User",
							},
						]}
						component={PageUserForm}
					/>
				}
			/>

			<Route
				path="/users/current/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-11"
						moduleName="User Current Edit"
						title="Users Current"
						subtitle="EDIT"
						pageId="PageUserEdit"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Users",
								link: "/users/current",
							},
							{
								name: "Current",
								link: "/users/current",
							},
							{
								name: "Edit User",
							},
						]}
						component={PageUserForm}
					/>
				}
			/>

			<Route
				path="/users/current/permission/:id"
				element={
					<PrivateRoute
						moduleCode="M-11"
						moduleName="User Current Edit Permission"
						title="User's Edit Permission"
						subtitle="EDIT"
						pageId="PageUserEdit"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Users",
								link: "/users/current",
							},
							{
								name: "Current",
								link: "/users/current",
							},
							{
								name: "Edit Permission",
							},
						]}
						component={PageUserPermission}
					/>
				}
			/>

			<Route
				path="/users/archived"
				element={
					<PrivateRoute
						moduleCode="M-12"
						moduleName="User Archived"
						title="Users"
						subtitle="ARCHIVED"
						pageId="PageUserArchived"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Users",
							},
							{
								name: "Archived",
							},
						]}
						component={PageUser}
					/>
				}
			/>

			<Route
				path="/users/archived/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-12"
						moduleName="User Archived Edit"
						title="Users Archived"
						subtitle="EDIT"
						pageId="PageUserEdit"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Users",
								link: "/users/archived",
							},
							{
								name: "Archived",
								link: "/users/archived",
							},
							{
								name: "Edit User",
							},
						]}
						component={PageUserForm}
					/>
				}
			/>

			{/* SETTINGS */}

			<Route
				path="/admin-settings"
				element={
					<PrivateRoute
						moduleCode="M-13"
						moduleName="Admin Settings"
						title="Admin"
						subtitle="SETTINGS"
						pageId="PageAdminSettings"
						pageHeaderIcon={faBooks}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Admin Settings",
							},
						]}
						component={PageSettings}
					/>
				}
			/>

			<Route path="/request-permission" element={<PageRequestPermission />} />
			<Route path="*" element={<Page404 />} />
		</Routes>
	);
}
